import React from 'react'
import {ReactComponent as JobOfferSVG} from '../svg/formations.svg'
import {ReactComponent as WebSiteSVG} from '../svg/webSite.svg'
import { motion } from 'framer-motion'
import { dashboardVariants } from '../animations/dashboard'
export default function Dashboard({compagnyData, setPages}) {


    return (
        <motion.div 
            exit = "exit"
            initial = "initial"
            animate = "animate"
            variants={dashboardVariants}
            transition={dashboardVariants.transition}
            className="dashboard"
        >
            <div className="dashboard__informationOffer">
                <div className="dashboard__informationOffer__description">
                <div className="dashboard__informationOffer__description__container">
                    <p className="dashboard__informationOffer__title">Description de la proposition</p>
                    <div>
                        <div><div>Nature :</div><div> {compagnyData.jobOffer.nature}</div></div>
                        <div><div>Durée :</div><div> {compagnyData.jobOffer.duration.content}</div></div>
                        <div><div>Possibilité d'évolution :</div><div> {compagnyData.jobOffer.evolutionPossibility ? "Oui" : "Non"}</div></div>
                    </div>
                </div>
                </div>
            </div>
            <div className="dashboard__buttons">
                <div onClick = {() => setPages("schoolOffers")}>
                    <JobOfferSVG />
                    <p>Offres de formations</p>
                </div>
            </div>
        </motion.div>
    )
}

/*                 <div onClick = {() => setPages("webSite")}>
                    <WebSiteSVG />
                    <p>Analyse site web</p>
                </div>*/