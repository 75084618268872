

export const schoolOffersData = [
    {
        id : 1, 
        nature  : "TP : assistant de comptabilité et d'administration", 
        duration : {
            value : 7, 
            content : "17 mois"
        }, 
        session : "01/02/2022 au 31/05/2021", 
        organism : "GRETA Reunion", 
        skills : [
            "Gérer la relation avec les clients et les fournisseurs de la TPE / PME-PMI / Association", 
            "Etablir et contrôler les documents comptables courants", 
            "Traiter les différentes opérations préparatoires à la clôture de l’exercice comptable en restituant une image fidèle des comptes", 
            "Evaluer la rentabilité des produits ou des services de l’entreprise", 
            "Effectuer et contrôler l’enregistrement et la diffusion de l’ensemble des opérations comptables courantes dans une TPE / PME-PMI / Association", 
            "Traiter les données comptables relatives à l'activité de l'entreprise."
        ]
    },
    {
        id : 2, 
        nature  : "BTS Comptabilité Gestion", 
        duration : {
            value : 18, 
            content : "18 à 36 mois"
        }, 
        session : "à tout moment", 
        organism : "Studi", 
        skills : [
            "Contrôle et traitement comptable des opérations commerciales", 
            "Contrôle et production de l'information financière", 
            "Gestion des obligations fiscales", 
            "Gestion des relations sociales", 
            "Analyse et prévision de l'activité", 
            "Analyse de la situation financière", 
            "Fiabilisation de l'information et du système d'information comptable"
        ]
    },
    {
        id : 3, 
        nature  : "Pré-graduate Assistant(e) Comptable", 
        duration : {
            value : 9, 
            content : "9 à 18 mois"
        }, 
        session : "à tout moment", 
        organism : "Studi", 
        skills : [
            "Comptabilité générale : traitement des opérations courantes", 
            "Tenue des comptes et rapprochement bancaire", 
            "Préparation de la clôture des comptes", 
            "Préparation des déclarations fiscales", 
        ]
    },
    {
        id : 4, 
        nature  : "TP Assistant comptable", 
        duration : {
            value : 18, 
            content : "18 mois"
        }, 
        session : "03/02/2021 au 31/08/2022", 
        organism : "SCPRINT FORMATION", 
        skills : [
            "Assurer les travaux courants de comptabilité",
            "Préparer la paie et les déclarations sociales courantes",
            "Préparer la fin d'exercice comptable et fiscal et présenter les indicateurs de gestion" 
        ]
    },
    {
        id : 5, 
        nature  : "Assistant Juridique", 
        duration : {
            value : 6, 
            content : "6 à 18 mois"
        }, 
        session : "à tout moment", 
        organism : "Studi", 
        skills : [
            "Gérer l’emploi du temps, le courrier ainsi que sa rédaction pour les courriers courants",
            "Assurer une veille documentaire des textes de loi",
            "Préparer les documents nécessaires aux procédures", 
            "Communiquer avec la clientèle"
        ]
    },

]










