export const buttonBaseVariants = {
    exit : {
        transform : 'scale(0)'
    },
    initial : {
        transform : 'scale(0)'
    },
    animate : {
        transform : 'scale(1)'
    },
}