import React, {useState} from 'react';
import logo from './logo.svg';
import './App.css';
import SchoolOffers from './components/SchoolOffers';
import Website from './components/WebSite';
import Dashboard from './components/Dashboard';
import Header from './components/Header';
import { compagnyData } from './datas/compagny';
import './styles/style.css'
import { schoolOffersData } from './datas/schoolOffers';
import {ReactComponent as ArrowLeft} from './svg/icons/arrowLeft.svg'
import {motion, AnimatePresence} from 'framer-motion'
import {buttonBaseVariants} from './animations/base'
function App() {
  const [pages, setPages] = useState("dashboard")
  const [detailOffer, setDetailOffer] = useState({
    status : false, 
    content : {}
})
  const compagny = compagnyData
  const schoolOffers = schoolOffersData

  return (
    <React.Fragment>
      {
        pages !== "dashboard" ? 
          <motion.div
            exit = "exit"
            initial = "initial"
            animate = "animate"
            variants={buttonBaseVariants}
            whileTap={{scale : 0.8}} 
            className='arrowLeft' 
            onClick = {() => {
              if(detailOffer.status) {
                setDetailOffer({
                  status : false, 
                  content : {}
                })
              } else {
                setPages("dashboard")
              }

            }}>
            <ArrowLeft />
          </motion.div>
        :   
          null
      }

      <Header 
          compagnyData = {compagny}
          pages = {pages}
      />
      <AnimatePresence>
      { 
          pages === "schoolOffers" ? 
            <SchoolOffers 
              compagnyData = {compagny}
              schoolOffers = {schoolOffers}
              detailOffer={detailOffer}
              setDetailOffer={setDetailOffer}
            />
          : 
            pages === "webSite" ? 
              <Website 
                compagnyData = {compagny}
              />
            : 
              <Dashboard 
                compagnyData = {compagny}
                setPages = {setPages}
              />
            

      }
      </AnimatePresence>
    </React.Fragment>
  );
}

export default App;
