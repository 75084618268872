import {ReactComponent as Logo} from '../svg/logo.svg'



export default function Header({compagnyData, pages}) {
    return (
        <div className = "header">
            {compagnyData.logo}
            <p>
            {
                    pages === "jobOffers" ? 
                        "Offre de formations"
                    : 
                        pages === "webSite" ? 
                            "Site web"
                        : 
                            "Proposition d'emploi"
                }
            </p>
        </div>
    )
}