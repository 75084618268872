import React, {useState} from "react"
import { motion } from "framer-motion"
import {schoolOffersVariants} from '../animations/schoolOffers'

function SchoolOffersList({schoolOffers, setDetailOffer}) {
    const handleClick = (offer) => {
        setDetailOffer({
                status : true, 
                content : offer
            })
    }
    return (
        <motion.div 
        exit = "exit"
        initial = "initial"
        animate = "animate"
        variants={schoolOffersVariants}
        transition={schoolOffersVariants.transition}
        className="schoolOffers">
            <div className="schoolOffers__thead">
                <div>Intitulé de la formation</div>
                <div>Organisme</div>
            </div>
            <div className="schoolOffers__tbody">
            {schoolOffers.map((element) => (
                <div key = {JSON.stringify(element)} onClick = {() => handleClick(element)}>
                    <div>{element.nature}</div>
                    <div>{element.organism}</div>
                </div>
            ))}
            </div>
        </motion.div>
    )
}

function DetailOffer({detailOffer}) {
    return (
        <motion.div 
        exit = "exit"
        initial = "initial"
        animate = "animate"
        variants={schoolOffersVariants}
        transition={schoolOffersVariants.transition}
        className="schoolOffers">
            <div className="schoolOffers__thead">
                {detailOffer.content.nature}
            </div>
            <div className="schoolOffers__detail">
                <div className="schoolOffers__detail__description">
                    <div><div>Nature :</div><div> {detailOffer.content.nature}</div></div>
                    <div><div>Durée :</div><div> {detailOffer.content.duration.content}</div></div>
                    <div><div>Organisme :</div><div> {detailOffer.content.organism}</div></div>
                    <div><div>Prochaine session :</div><div> {detailOffer.content.session}</div></div>

                </div>
                <div className="schoolOffers__detail__skills">
                    <div className="schoolOffers__detail__skills__title">Compétences visées</div>
                    <div className="schoolOffers__detail__skills__content">
                    {
                        detailOffer.content.skills.map(element => (
                            <div key = {JSON.stringify(element)}>
                                {element} 
                            </div>
                        ))
                    }
                    </div>


                </div>
                
            </div>
        </motion.div>
    )
}

export default function SchoolOffers({schoolOffers, detailOffer, setDetailOffer}) {


    return (
        <React.Fragment>
            {
                !detailOffer.status ? 
                    <SchoolOffersList 
                        schoolOffers = {schoolOffers}
                        setDetailOffer = {setDetailOffer}
                    />
                : 
                    <DetailOffer 
                        detailOffer = {detailOffer}
                    />

            }

        </React.Fragment>
    )
}