import React from 'react'
import {ReactComponent as Logo} from '../svg/logo.svg'

export const compagnyData = {
    logo : <Logo />, 
    jobOffer : {
        nature : "Assistant gestion comptable et administrative", 
        duration : {
            value : 6, 
            content  : "6 mois ou plus"
        }, 
        evolutionPossibility : true
    }
}