

export const schoolOffersVariants = {
    exit : {
        y : 1000
    }, 
    initial : {
        y : 1000
    }, 
    animate : {
        y : 0
    }, 
    transition : {
        type : "linear"
    }
}